import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { CommentIcon } from '../icons/comment-icon';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './post-list-classic-header.scss';
import { ViewCountIcon } from '../icons/view-count-icon';
import flowRight from 'lodash/flowRight';
import { EmotionsIcon } from '../icons/emotions-icon';
import LikeHeart from 'wix-ui-icons-common/on-stage/LikeHeart';
import ThumbsUpEmpty from 'wix-ui-icons-common/on-stage/ThumbUp';
import { PostBreakpoints, useForumWidthContext } from '../responsive-listener';

const PostListClassicHeader = ({
  t,
  isViewsCountEnabled,
  pagination,
  showReactionsCount,
  reactionsIcon,
}) => {
  const width = useForumWidthContext();
  const showViewsCount = width > PostBreakpoints.sm + IconColumnWidth;
  const showLikesCount = width > PostBreakpoints.sm;
  const showCommentsCount = width > PostBreakpoints.sm - IconColumnWidth;

  const getReactionsIcon = () => {
    const defaultIcon = EmotionsIcon;

    switch (reactionsIcon) {
      case 'heart':
        return LikeHeart;
      case 'thumbsup':
        return ThumbsUpEmpty;
      case 'emotions':
        return EmotionsIcon;
      default:
        return defaultIcon;
    }
  };
  const ReactionsIcon = getReactionsIcon();

  return (
    <thead>
      <tr className={styles.row}>
        <th className={classNames(styles.titleHeader, styles.left, 'forum-text-color')}>
          {pagination}
        </th>
        {showCommentsCount && (
          <th className={styles.iconHeader}>
            <CommentIcon className="forum-icon-stroke" title={t('post-list-classic.comments')} />
          </th>
        )}
        {showLikesCount && showReactionsCount && (
          <th className={styles.iconHeader}>
            <ReactionsIcon className="forum-icon-fill" title={t('post-list-classic.emotions')} />
          </th>
        )}
        {isViewsCountEnabled && showViewsCount && (
          <th className={styles.iconHeader}>
            <ViewCountIcon className="forum-icon-fill" title={t('post-list-classic.views')} />
          </th>
        )}
        <th className={classNames(styles.timeAgoHeader, styles.left, 'forum-text-color')}>
          {t('post-list-classic.recent-activity')}
        </th>
        <th className={styles.moreButtonHeader}>
          <span className={styles.a11yOnly}>{t('post-list-classic.table-header-more-button')}</span>
        </th>
      </tr>
    </thead>
  );
};

PostListClassicHeader.propTypes = {
  t: PropTypes.func.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  pagination: PropTypes.any,
};

export const IconColumnWidth = 72;

export default flowRight(withTranslate)(PostListClassicHeader);
