import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight, size } from 'lodash';
import { DISCUSSION, QUESTION, getLastPage } from '@wix/communities-forum-client-commons';
import { Loader } from '@wix/communities-forum-client-commons/components';

import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import PostListClassicHeader from '../post-list-classic-header';
import PostListItemClassic from '../post-list-item-classic';
import { HorizontalSeparator } from '../separator';
import { PaginationClassic } from '../pagination';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withReactionsConfig from '../../hoc/with-reactions-config';
import withTranslate from '../../../common/components/with-translate/with-translate';

import { getIsViewsCountEnabled } from '../../selectors/app-settings-selectors';
import { getPostPageSize } from '../../constants/pagination';
import NoPostsFiltered from '../no-posts-filtered';
import styles from './post-list-classic.scss';
import withAuth from '../../hoc/with-auth';
import { PostListControls } from './post-list-controls.component';
import { getForumContainerBreakpointValue } from '../../selectors/layout-selectors';
import { getActiveFeedName } from '../../containers/header-navigation/store/header-navigation.selectors';
import { FeedType } from '../../containers/header-navigation/models/feed-type.enum';
import { ForumWidthContext } from '../responsive-listener';

class PostListClassic extends Component {
  static contextType = ForumWidthContext;
  componentDidUpdate(prevProps) {
    const { page, entityCount, navigateWithinForum, buildPageUrl, category, postsPerPage } =
      this.props;

    const lastPage = getLastPage(entityCount, postsPerPage);

    if (category._id !== prevProps.category._id) {
      return;
    }

    if (entityCount < prevProps.entityCount && page > lastPage) {
      navigateWithinForum(buildPageUrl(lastPage));
    }
  }

  renderPagination(isTop) {
    const placement = isTop ? 'top' : 'bottom';
    const dataHook = `pagination-${placement}`;
    return (
      <div className={styles.paginationSpacing}>
        <div className={styles.paginationContainer}>
          <PaginationClassic
            page={this.props.page}
            entityCount={this.props.entityCount}
            showPerPage={this.props.postsPerPage}
            onChange={this.handlePageChange}
            buildPageUrl={this.props.buildPageUrl}
            changePageOnClick={false}
            dataHook={dataHook}
          />
        </div>
      </div>
    );
  }

  renderControls({ top }) {
    const {
      category,
      showCategoryFilter,
      isAuthenticated,
      showCreatePostAction,
      postTypes,
      customCtaLabel,
    } = this.props;
    const width = this.context;
    const breakpointValue = getForumContainerBreakpointValue(width);

    return (
      <PostListControls
        className={styles.spacing}
        dataHook="post-list-classic-controls"
        isTop={top}
        showCategoryFilter={showCategoryFilter}
        breakpointValue={breakpointValue}
        isAuthenticated={isAuthenticated}
        showCreatePostAction={showCreatePostAction}
        postTypes={postTypes}
        customCtaLabel={customCtaLabel}
        category={category}
        renderPagination={(isTop) => this.renderPagination(isTop)}
      />
    );
  }

  handlePageChange = ({ page, buttonType }) => {
    this.props.changePage({
      page,
      meta: {
        bi: {
          buttonType,
        },
      },
    });
    this.props.onPageChange(page);
  };

  renderSeparator(key = 'separator', isFirstItem) {
    return (
      <tr key={key} aria-hidden="true">
        <td className={styles.separatorColumn} colSpan="100">
          <HorizontalSeparator className={isFirstItem && styles.topSeparator} />
        </td>
      </tr>
    );
  }

  renderEmptyState = () => {
    const { showMemberPosts, emptyStateFragment } = this.props;
    return (
      <React.Fragment>
        <HorizontalSeparator />
        {emptyStateFragment || <NoPostsFiltered noMemberPosts={showMemberPosts} />}
      </React.Fragment>
    );
  };

  renderContent() {
    const {
      posts,
      query,
      onLikeClick,
      isViewsCountEnabled,
      showCategoryLink,
      hasActiveFilter,
      emptyStateFragment,
      reactionsConfig,
      feedName,
    } = this.props;

    const shouldShowEmptyState = emptyStateFragment || (!posts.length && hasActiveFilter);
    const showReactionsCount = reactionsConfig.type !== 'none';
    return (
      <div className={styles.spacing} data-hook="post-list-classic">
        <table className={styles.table}>
          <PostListClassicHeader
            pagination={shouldShowEmptyState ? null : this.renderPagination(true)}
            isViewsCountEnabled={isViewsCountEnabled}
            reactionsIcon={
              feedName === FeedType.Posts ||
              feedName === FeedType.MyPosts ||
              reactionsConfig.type === 'emotions'
                ? 'emotions'
                : reactionsConfig?.mainEmotion
            }
            showReactionsCount={showReactionsCount}
          />
          <tbody>
            {!shouldShowEmptyState &&
              posts.map((post, i) => [
                this.renderSeparator(`separator-${post?._id}`, i === 0),
                <PostListItemClassic
                  key={post?._id ?? i}
                  post={post}
                  query={query}
                  onLikeClick={onLikeClick}
                  showCategoryLink={showCategoryLink}
                  showReactionsCount={showReactionsCount}
                />,
              ])}
          </tbody>
        </table>
        {shouldShowEmptyState && this.renderEmptyState()}
      </div>
    );
  }

  renderLoader() {
    return (
      <div className={styles.loader} data-hook="post-list-loader">
        <Loader />
      </div>
    );
  }

  render() {
    const { isLoading, borderWidth, contentFontClassName, isLoaded, posts, postsPerPage } =
      this.props;

    const className = classNames(
      styles.container,
      'forum-card-background-color',
      'forum-card-border-color',
      contentFontClassName,
    );
    const hasBottomControls = size(posts) >= postsPerPage / 3 && isLoaded;
    return (
      <div className={className} style={{ borderWidth }}>
        {isLoaded && this.renderControls({ top: true })}
        {isLoaded && <HorizontalSeparator />}
        {isLoading ? this.renderLoader() : this.renderContent()}
        {hasBottomControls && <HorizontalSeparator />}
        {hasBottomControls && this.renderControls({ top: false })}
      </div>
    );
  }
}

PostListClassic.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  query: PropTypes.string,
  page: PropTypes.number,
  entityCount: PropTypes.number,
  borderWidth: PropTypes.number.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  showCreatePostAction: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isViewsCountEnabled: PropTypes.bool,
  showCategoryLink: PropTypes.bool,
  buildPageUrl: PropTypes.func,
  hasActiveFilter: PropTypes.bool,
  navigateWithinForum: PropTypes.func,
  uniquePostTypesInAllCategories: PropTypes.array,
  showCategoryFilter: PropTypes.bool,
  showMemberPosts: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  customCtaLabel: PropTypes.string,
  postTypes: PropTypes.arrayOf(PropTypes.oneOf([QUESTION, DISCUSSION])),
  emptyStateFragment: PropTypes.node,
  postsPerPage: PropTypes.number,
  reactionsConfig: PropTypes.object,
};

const mapRuntimeToPros = (state, ownProps, actions, host) => ({
  isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
  changePage: actions.changePage,
  navigateWithinForum: actions.navigateWithinForum,
  postsPerPage: getPostPageSize(state, host.style),
  feedName: getActiveFeedName(state),
});

export default flowRight(
  connect(mapRuntimeToPros),
  withCardBorderWidth,
  withFontClassName,
  withTranslate,
  withAuth,
  withReactionsConfig,
)(PostListClassic);
