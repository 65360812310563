import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useReactionsConfig } from '../hooks/use-reactions-config';

export default function withReactionsConfig(WrappedComponent: React.ComponentType<any>) {
  type WrapperProps = {
    category: any;
  };

  const Wrapper: React.FC<WrapperProps> = (props) => {
    const reactionsConfig = useReactionsConfig(props.category);

    return <WrappedComponent {...props} reactionsConfig={reactionsConfig} />;
  };

  hoistNonReactStatics(Wrapper, WrappedComponent);

  return Wrapper;
}
